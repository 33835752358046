import { GrantService } from '@/modules/grant/grant-service';
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import RelationToManyField from '@/shared/fields/relation-to-many-field';
import Permissions from '@/security/permissions';

export class GrantField {
  static relationToOne(name, label, options) {
    return new RelationToOneField(
      name,
      label,
      '/grant',
      Permissions.values.grantRead,
      Permissions.values.grantNavigate,
      GrantService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        return {
          id: record.id,
          label: `${record.fundingAgency.name} <${record.contactPerson}>`,
        };
      },
      options,
    );
  }

  static relationToMany(name, label, options) {
    return new RelationToManyField(
      name,
      label,
      '/grant',
      Permissions.values.grantRead,
      Permissions.values.grantNavigate,
      GrantService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        return {
          id: record.id,
          label: `${record.fundingAgency.name} <${record.contactPerson}>`,
        };
      },
      options,
    );
  }
}

