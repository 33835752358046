import { i18n } from '@/i18n';
import { SettingsStorage } from '@/modules/settings/settings-storage';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import FilesField from '@/shared/fields/files-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import Permissions from '@/security/permissions';
import Storage from '@/security/storage';

function label(name) {
  return i18n(`entities.template.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.template.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  template: new FilesField(
    'template',
    label('template'),
    Storage.values.templateTemplate,
    Permissions.getFilePermissions('templateFileFields', 'template'),
    {
      max: SettingsStorage.getByName('MAX_TEMPLATES_FILE_SIZE'),
      formats: ['pdf', 'doc', 'docx', 'xlsx', 'xltx', 'xltm'],
    },
  ),
  templateType: new EnumeratorField(
    'templateType',
    label('templateType'),
    [
      {
        id: 'costs',
        label: enumeratorLabel('templateType', 'costs'),
      },
      {
        id: 'gantt',
        label: enumeratorLabel('templateType', 'gantt'),
      },
      {
        id: 'projectReport',
        label: enumeratorLabel('templateType', 'projectReport'),
      },
      {
        id: 'descriptionThirdPartyFunding',
        label: enumeratorLabel(
          'templateType',
          'descriptionThirdPartyFunding',
        ),
      },
      {
        id: 'ethicsCommitteeGuidelines',
        label: enumeratorLabel(
          'templateType',
          'ethicsCommitteeGuidelines',
        ),
      },
      {
        id: 'cooperationAgreement',
        label: enumeratorLabel(
          'templateType',
          'cooperationAgreement',
        ),
      },
      {
        id: 'employmentContract',
        label: enumeratorLabel('templateType', 'employmentContract'),
      },
      {
        id: 'projectPlanSelfFinancing',
        label: enumeratorLabel(
          'templateType',
          'projectPlanSelfFinancing',
        ),
      },
    ],
    {},
  ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class TemplateModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
