/**
 * List of project create statuses.
 */
class PrepareFundingStatus {
    static get values() {
      return { 
        grants: 'grants',
        projectNoAssignmentByAccounting: 'projectNoAssignmentByAccounting',                       
        completed: 'completed',
      };
    }
  }
  
  module.exports = PrepareFundingStatus;