<template>
  <div>
    <form-wizard
      @on-complete="doSubmitFinish"
      :start-index="getWizardStartIndex"
      color="#393939"
      error-color="#ff4949"
      step-size="xs"
      layout="hortzontal"
      ref="formWizard"
      title=""
      subtitle=""
    >
      <tab-content icon="ti-user" title="Grants">
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="formGrants"
          v-if="model"
          :disabled="isCompleted"
        >
          <el-form-item
            :label="fields.fundingEnum.label"
            :prop="fields.fundingEnum.name"
            :required="fields.fundingEnum.required"
            :disabled="true"
          >
            <el-col :lg="8" :md="8" :sm="8">
              <el-select
                :disabled="true"
                placeholder
                v-model="model[fields.fundingEnum.name]"
              >
                <el-option :value="undefined">--</el-option>
                <el-option
                  :key="option.id"
                  :label="option.label"
                  :value="option.id"
                  v-for="option in fields.fundingEnum.options"
                ></el-option>
              </el-select>
            </el-col>
            <el-col
              :lg="13"
              :md="16"
              :sm="24"
              v-if="isThirdPartyFundedProject"
            >
              <app-grant-autocomplete-input
                :fetchFn="fields.grants.fetchFn"
                :mapperFn="fields.grants.mapperFn"
                :showCreate="!modal"
                :showSelectElement="false"
                :parentId="model.id"
                :filterServerDataSourceBy="filterGrantBy"
                mode="multiple"
                v-model="model[fields.grants.name]"
              ></app-grant-autocomplete-input>
            </el-col>
          </el-form-item>

          <!-- Template to download for Ethics Committee Guidelines-->
          <app-template-view-file-page
            templateType="projectPlanSelfFinancing"
            v-if="!isThirdPartyFundedProject"
          ></app-template-view-file-page>

          <app-grant-list-table
            @removeExternalApplication="removeExternalApplication"
            v-if="isThirdPartyFundedProject"
            v-bind:isInPojectImplementation="true"
          ></app-grant-list-table>
        </el-form>
      </tab-content>

      <tab-content
        icon="ti-user"
        title="Accounting - assign Project number"
      >
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="formAssigningProjectNumber"
          v-if="model"
          :disabled="isCompleted || !hasPermissionToAssignProjectNo"
        >
          <el-form-item
            :label="fields.accountingProjectNo.label"
            :prop="fields.accountingProjectNo.name"
            :required="fields.accountingProjectNo.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <el-input
                ref="focus"
                v-model="model[fields.accountingProjectNo.name]"
              />
            </el-col>
          </el-form-item>
        </el-form>
      </tab-content>

      <!-- Wzard Buttons -->
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <div class="wizard-btn" v-if="props.activeTabIndex > 0">
            <wizard-button
              v-if="props.activeTabIndex > 0"
              @click.native="props.prevTab()"
              :style="props.fillButtonStyle"
              ><app-i18n code="common.previous"></app-i18n
            ></wizard-button>
          </div>
          <div class="wizard-btn">
            <wizard-button
              icon="el-icon-fa-floppy-o"
              @click.native="doCloseProject"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              v-if="!(isCompleted || isProjectClosed)"
              ><app-i18n
                code="common.closeProjectNoFunding"
              ></app-i18n
            ></wizard-button>
          </div>
        </div>
        <div class="wizard-footer-right">
          <div class="wizard-btn">
            <wizard-button
              icon="el-icon-fa-floppy-o"
              @click.native="doRequestAssignmentByAccounting"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              v-if="
                props.activeTabIndex === 0 &&
                  !(
                    !props.activeTabIndex === 0 ||
                    !grantsDecisionsPositive ||
                    status != 'grants' ||
                    isCompleted ||
                    isProjectClosed
                  )
              "
              ><app-i18n
                code="common.requestProjectNoAssignmentByAccounting"
              ></app-i18n
            ></wizard-button>
          </div>
          <div
            class="wizard-btn"
            v-if="!isSelfFinancedProject || fundingEnumUpdated"
          >
            <wizard-button
              icon="el-icon-fa-floppy-o"
              @click.native="doSubmit"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              v-if="
                (!isSelfFinancedProject || fundingEnumUpdated) &&
                  !(isSaveBtnDisabled || isProjectClosed)
              "
              ><app-i18n code="common.save"></app-i18n
            ></wizard-button>
          </div>
          <div class="wizard-btn">
            <wizard-button
              @click.native="props.nextTab()"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              v-if="!props.isLastStep && !isSelfFinancedProject && (isSelfFinancedProject && isThirdPartyFundedProject)"
              ><app-i18n code="common.next"></app-i18n
            ></wizard-button>
            <wizard-button
              @click.native="doSubmitFinish()"
              class="wizard-footer-right finish-button"
              :style="props.fillButtonStyle"
              v-if="isFinishButtonShown"
              ><app-i18n code="common.finish"></app-i18n
            ></wizard-button>
          </div>
        </div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ProjectFundingModel } from '@/modules/project-funding/project-funding-model';
import GrantListTable from '@/modules/grant/components/grant-list-table';
import Templates from '@/modules/template/components/template-view-file-page';
import { EventBus } from '@/eventBus';
import ProjectFundingStatus from '@/shared/enums/prepareFundingStatus';
import { ProjectFundingPermissions } from '@/modules/project-funding/project-funding-permissions';
import { i18n } from '@/i18n';

const statusEnum = ProjectFundingStatus.values;
const { fields } = ProjectFundingModel;
const formSchema = new FormSchema([
  fields.id,
  fields.fundingEnum,
  fields.grants,
  fields.accountingProjectNo,
  fields.status,
  fields.updatedAt,
]);

export default {
  name: 'app-project-funding-form',

  props: [
    'isEditing',
    'record',
    'saveLoading',
    'status',
    'modal',
    'grantsDecisionsPositive',
    'isThirdPartyFundedProject',
    'isSelfFinancedProject',
    'fundingAgencyEnum',
  ],

  components: {
    [Templates.name]: Templates,
    [GrantListTable.name]: GrantListTable,
  },

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      isMounted: false,
      filterGrantBy: null,
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  mounted() {
    this.isMounted = true;
    this.model.fundingEnum = this.fundingAgencyEnum;
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      projectStatus: 'project/form/status',
      currentUser: 'auth/currentUser',
    }),

    isFinishButtonDisabled() {
      if (this.status === statusEnum.grants) {
        if (this.isSelfFinancedProject && !this.fundingEnumUpdated) {
          return false;
        } else {
          return true;
        }
      } else if (
        this.status === statusEnum.projectNoAssignmentByAccounting
      ) {
        return (
          this.saveLoading ||
          this.isCompleted ||
          !this.hasPermissionToAssignProjectNo
        );
      }
      return true;
    },

    isProjectClosed() {
      return (
        this.projectStatus === 'CLOSED' ||
        this.projectStatus === 'COMPLETED'
      );
    },

    isSaveBtnDisabled() {
      if (
        this.status === statusEnum.projectNoAssignmentByAccounting &&
        !this.hasPermissionToAssignProjectNo
      ) {
        return true;
      } else if (this.isCompleted) {
        return true;
      }
      return false;
    },

    isFinishButtonShown() {
      return this.isMounted
        ? (this.isLastStep || this.isSelfFinancedProject) &&
            !(this.isFinishButtonDisabled || this.isProjectClosed)
        : false;
    },

    hasPermissionToAssignProjectNo() {
      return new ProjectFundingPermissions(this.currentUser)
        .assignProjectNo;
    },

    fields() {
      return fields;
    },

    isCompleted() {
      return this.model.status === statusEnum.completed;
    },

    isFundingTypeSaved() {
      return (
        !!this.record.fundingEnum &&
        this.record.status === statusEnum.grants
      );
    },

    isFundingEnumSaved() {
      return !!this.record.fundingEnum;
    },

    getWizardStartIndex() {
      switch (this.status) {
        case statusEnum.grants:
          return 0;
        case statusEnum.projectNoAssignmentByAccounting:
          return 1;
        case statusEnum.completed:
          return this.isSelfFinancedProject ? 0 : 1;
        default:
          return 0;
      }
    },

    isLastStep() {
      return this.$refs.formWizard.isLastStep;
    },

    activeTabIndex() {
      return this.$refs.formWizard.activeTabIndex;
    },

    fundingEnumUpdated() {
      return !(this.model.fundingEnum === this.record.fundingEnum);
    },
  },

  watch: {
    'model.grants': function(payload) {
      // when the parentId prop changes, this function will be fired.
      EventBus.$emit('updateGrantsList', payload);
    },

    record: function() {
      this.doUpdateModel();
    },
  },

  methods: {
    isSameElement(el, index, arr) {
      if (index === 0) {
        return true;
      } else {
        return el.status === arr[index - 1].status;
      }
    },

    doReset() {
      this.model = formSchema.initialValues(this.record);
    },

    doCancel() {
      this.$emit('cancel');
    },

    async doEmit() {
      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },

    async doSubmit() {
      if (this.status === statusEnum.completed) {
        return;
      }

      switch (this.activeTabIndex) {
        case 0:
          await this.validateGrants();
          await this.setStatus(statusEnum.grants);
          break;
        case 1:
          await this.validateAccointingAssignementOfProjectNo();
          await this.setStatus(
            statusEnum.projectNoAssignmentByAccounting,
          );
          break;

        // completed is missings
        default:
          break;
      }

      await this.doEmit();
    },

    async doSubmitFinish() {
      if (!(this.status === statusEnum.completed)) {
        await this.setStatus(statusEnum.completed);
        await this.doEmit();
      }
    },

    async setStatus(value) {
      if (this.model.status === statusEnum.completed) {
        return;
      }
      this.model.status = value;
    },

    async doCloseProject() {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return EventBus.$emit('closeProject');
      } catch (error) {
        // no
      }
    },

    async doRequestAssignmentByAccounting() {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        this.$refs.formWizard.changeTab(0, 1);
        return this.$emit('requestAssignmentByAccounting', {
          id: this.model.id,
        });
      } catch (error) {
        // no
      }
    },

    //filter employment contracts autocomplete after row has been deleted from table
    removeExternalApplication(payload) {
      const filteredGrant = this.model.grants.filter(
        (f) => f.id != payload.id,
      );
      this.model.grants = filteredGrant;
      this.filterGrantBy = payload.id;
    },

    doUpdateModel() {
      this.model = formSchema.initialValues(this.record || {});
    },

    async validateGrants() {
      return this.$refs.formGrants.validate();
    },

    async validateAccointingAssignementOfProjectNo() {
      return this.$refs.formAssigningProjectNumber.validate();
    },
  },
};
</script>

<style></style>
