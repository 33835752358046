import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import EnumeratorField from '@/shared/fields/enumerator-field';
import StringField from '@/shared/fields/string-field';
import { GrantField } from '@/modules/grant/grant-field';

function label(name) {
  return i18n(`entities.projectFunding.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.projectFunding.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  fundingEnum: new EnumeratorField('fundingEnum', label('fundingEnum'), [
    { id: 'thirdPartyFunding', label: enumeratorLabel('fundingEnum', 'thirdPartyFunding') },
    { id: 'selfFinancedProject', label: enumeratorLabel('fundingEnum', 'selfFinancedProject') },
  ], {
    required: true,
  }),  
  grants: GrantField.relationToMany('grants', label('grants'), {
    required: true,
  }),
  accountingProjectNo: new StringField('accountingProjectNo', label('accountingProjectNo'), {
    max: 50,
    required: true,
  }),  
  status: new EnumeratorField('status', label('status'), [
    { id: 'ethicsCommittee', label: enumeratorLabel('status', 'ethicsCommittee') },
    { id: 'cooperationPartnerContract', label: enumeratorLabel('status', 'cooperationPartnerContract') },
    { id: 'employmentContracts', label: enumeratorLabel('status', 'employmentContracts') },
    { id: 'projectFunding', label: enumeratorLabel('status', 'projectFunding') },
    { id: 'completed', label: enumeratorLabel('status', 'completed') },
  ], {}),

  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class ProjectFundingModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
