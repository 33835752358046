<template>
  <div v-if="templates">
    <div v-bind:key="template.id" v-for="template in templates.rows">
      <app-view-item-file
        :label="fields.template.label"
        :value="presenter(template, 'template')"
        :permissions="fields.template.permissions"
      ></app-view-item-file>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { TemplateModel } from '@/modules/template/template-model';
import { TemplateService } from '@/modules/template/template-service';
import Errors from '@/shared/error/errors';

const { fields } = TemplateModel;

export default {
  name: 'app-template-view-file-page',

  props: ['templateType', 'permissions'],

  data() {
    return {
      templates: null,
    };
  },

  /* async created() {
    await this.doGetTemplate(this.templateType);
  }, */
  async created() {
    await this.doGetTemplate(this.templateType);
  },
  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    async doGetTemplate() {
      try {
        this.templates = await TemplateService.list({
          templateType: this.templateType,
        });
        // array of records is returned so we gat first row to the record
        /* this.record = response.rows.filter(
          (row) => row.templateType === this.templateType,
        ); */
      } catch (error) {
        Errors.handle(error);
      }
    },

    presenter(record, fieldName) {
      return TemplateModel.presenter(record, fieldName);
    },
  },
};
</script>
