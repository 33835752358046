<template>
  <div>
    <div
      class="app-page-spinner"
      v-if="findLoading"
      v-loading="findLoading"
    ></div>

    <app-project-funding-form
      ref="appProjectFundingForm"
      :isEditing="isEditing"
      :record="record"
      :saveLoading="saveLoading"
      :status="status"
      :grantsDecisionsPositive="grantsDecisionsPositive"
      :isThirdPartyFundedProject="isThirdPartyFundedProject"
      :isSelfFinancedProject="isSelfFinancedProject"
      :fundingAgencyEnum="fundingAgencyEnum"
      @submit="doSubmit"
      @requestAssignmentByAccounting="doRequestAssignmentByAccounting"
      v-if="isDataReady"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProjectFundingForm from '@/modules/project-funding/components/project-funding-form';
import { EventBus } from '@/eventBus';
/* import Message from '@/shared/message/message'; */
/* import { i18n } from '@/i18n'; */
import ProjectStepEnum from '@/shared/enums/projectStep';

export default {
  name: 'app-project-funding-form-project-page',

  components: {
    [ProjectFundingForm.name]: ProjectFundingForm,
  },

  data() {
    return {
      projectStepEnum: ProjectStepEnum.values,
    };
  },

  computed: {
    ...mapGetters({
      record: 'projectFunding/form/record',
      findLoading: 'projectFunding/form/findLoading',
      saveLoading: 'projectFunding/form/saveLoading',
      status: 'projectFunding/form/status',
      isThirdPartyFundedProject:
        'projectFunding/form/isThirdPartyFundedProject',
      isSelfFinancedProject:
        'projectFunding/form/isSelfFinancedProject',
      projectId: 'project/form/projectId',
      fundingAgencyEnum: 'project/form/fundingAgencyEnum',
      projectFundingId: 'project/form/projectFundingId',
      grantsDecisionsPositive: 'grant/list/grantsDecisionsPositive',
    }),

    isDataReady() {
      return this.record && !this.findLoading;
    },

    isEditing() {
      return !!this.projectFundingId;
    },
  },

  async created() {
    if (this.isEditing) {
      await this.doFind(this.projectFundingId);
    } else {
      await this.doNew();
      await this.doSubmit({
        id: undefined,
        values: {},
      });
    }

    await this.doFilterGrantsList();

    EventBus.$on('updateGrantsList', (payload) => {
      this.doFilterGrantsList(payload);
    });
  },

  beforeDestroy() {
    EventBus.$off('updateGrantsList');
  },

  methods: {
    ...mapActions({
      doFind: 'projectFunding/form/doFind',
      doNew: 'projectFunding/form/doNew',
      doUpdate: 'projectFunding/form/doUpdate',
      doCreate: 'projectFunding/form/doCreate',
      doSetProjectFundingId: 'project/form/doSetProjectFundingId',
      doUpdateProject: 'project/form/doUpdate',
      doReloadProject: 'project/form/doReload',
      doUpdateProjectStep: 'project/form/doUpdateProjectStep',
      doRequestAssignmentNumberByAccounting:
        'projectFunding/form/doRequestAssignmentNumberByAccounting',
      doFetchGrants: 'grant/list/doFetch',
    }),

    async doSubmit(payload) {
      if (this.isEditing) {
        const response = await this.doUpdate(payload);
        if (response) {
          if (payload.values.status === 'completed') {
            /* await this.doUpdateProjectStep(
              this.projectStepEnum.gantt,
            ); */
            await this.navigateToProjectGantt();
            await this.doReloadProject();
          }
        }
      } else {
        //project funding is already created with the project on the server side
        const id = await this.doCreate(payload.values);
        this.doSetProjectFundingId(id);
        await this.doUpdateProject();
      }
    },

    async doFilterGrantsList(payload) {
      const filter = this.setFilter(payload);

      return this.doFetchGrants({
        filter,
      });
    },

    setFilter(payload) {
      let id;
      if (payload != undefined || payload != null) {
        id = payload.map(function (f) {
          return f.id;
        });
      }

      return {
        projectFunding: this.projectFundingId,
        id,
      };
    },

    async doRequestAssignmentByAccounting() {
      await this.doRequestAssignmentNumberByAccounting(
        this.projectFundingId,
      );
    },

    navigateToProjectGantt() {
      EventBus.$emit('navigateToProjectGantt');
    },
  },
};
</script>

<style>
</style>
