import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class ProjectFundingPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.projectFundingRead,
    );
    this.create = permissionChecker.match(
      Permissions.values.projectFundingCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.projectFundingEdit,
    );
    this.assignProjectNo = permissionChecker.match(
      Permissions.values.projectFundingAssignProjectNo,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.projectFundingNavigate,
    );
  }
}
